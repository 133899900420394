import { useEffect, useState } from 'react'
import { useMap } from 'react-leaflet'
import useTWSMapContext from '../hooks/UseTWSContext'
import { Map } from 'leaflet'
import { useSelector } from 'react-redux'

export default function TWSComponents() {
  const { twsElements } = useTWSMapContext()
  const leafletMap = useMap<Map | null>()
  const [size, setSize] = useState({ width: 0, height: 0 })
  const locations = useSelector((state: RootState) => state.user.locations)
  var defaultLocation = useSelector((state: RootState) => {
    if (state.user.defaultLocation) {
      return state.user.defaultLocation
    }
    if (state.user.locations && state.user.locations.length > 0) {
      return state.user.locations[0] // Choose the first location as default
    }
    return null // Return null if there's no default location and the user has no locations
  })
  if (typeof defaultLocation === 'undefined' || defaultLocation === null || (typeof defaultLocation === 'object' && Object.keys(defaultLocation).length === 0)) {
    defaultLocation = {
      "latitude": "39.8333333",
      "longitude": "-98.585522",
    };
}

  useEffect(() => {
    if (!defaultLocation || locations?.length === 0) {
      leafletMap.setZoom(5)
    } else {
      leafletMap.setView(
        {
          lat: defaultLocation?.latitude,
          lng: defaultLocation?.longitude,
        },
        13,
        {
          animate: true,
        }
      )
    }
  }, [locations])

  useEffect(() => {
    const updateSize = () => {
      const newSize = leafletMap.getSize()
      setSize({ width: newSize.x, height: newSize.y })
    }
    updateSize()

    leafletMap.on('resize', updateSize)

    if (leafletMap) {
      setSize(leafletMap.getSize())
    }
    // Cleanup
    return () => {
      leafletMap.off('resize', updateSize)
    }
  }, [leafletMap])

  return <>{twsElements}</>
}
