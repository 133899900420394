import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { TWS_API_URL } from '../../../environment/apis.config'
import useErrorStatus from '../../../hooks/UseErrorStatus'
import RouteParams from '../../../interfaces/RouteCast'
import { incrementRoute, setEvalApiResponse, setSelectedIndex, setWaypoints } from './routecastSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'

interface SavedRoutesMenuProps {
  routes: Array<RouteParams>
  setRouteArray: Dispatch<SetStateAction<Array<RouteParams>>>
  setShowSaveMenu: Dispatch<SetStateAction<boolean>>
}

const initRouteState: RouteParams = {
  selectedDate: new Date(),
  speedVal: 10,
  yellow1: 5,
  red1: 10,
  yellow2: 5,
  red2: 10,
  windSpeedInputs: {
    yellow: 9,
    red: 15,
  },
  eightyMWindInputs: {
    yellow: 10,
    red: 15,
  },
  ceilingInputs: { yellow: 1000, red: 600 },
  visibilityInputs: {
    yellow: 4,
    red: 3,
  },
  selectedOpValue: 'WIND_RISK',
  sliderValue: 0,
  selectedParameter: 'winds',
  routeName: '',
}

const SavedRoutesMenu = ({
  routes,
  setRouteArray,
  setShowSaveMenu,
}: SavedRoutesMenuProps): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [routeList, setRouteList] = useState<Array<any>>([])
  const [showNoRoutesMessage, setShowNoRoutesMessage] = useState<boolean>(false)
  const errorStatus = useErrorStatus()
  const dispatch = useDispatch()

  const getAllSavedRoutes = async () => {
    const routes = await fetch(`${TWS_API_URL}/routes`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((data) => data.json())
    if (!routes.length) {
      setShowNoRoutesMessage(true)
    }
    setRouteList([...routes])
  }

  const deleteSavedRoute = async (routeId: string) => {
    setIsLoading(true)
    const status = await fetch(`${TWS_API_URL}/routes/${routeId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((res) => res.json())

    if (status.message === 'Route deleted.') {
      errorStatus.addMessage(
        'Route Deleted Successfully!',
        200,
        'success',
        true
      )
    } else {
      errorStatus.addMessage(
        'There was an error while deleting the route. Please try again!',
        400,
        'error',
        true
      )
    }
    getAllSavedRoutes()
    setIsLoading(false)
  }

  const editSelectedRoute = async (routeId: string) => {
    const getRoute = await fetch(`${TWS_API_URL}/routes/${routeId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((res) => res.json())
    const newRoute = {
      ...initRouteState,
      routeName: getRoute.label,
      selectedDate: new Date(),
      ceilingInputs: {
        red: getRoute.req.redCeilingFeet,
        yellow: getRoute.req.yellowCeilingFeet,
      },
      visibilityInputs: {
        red: getRoute.req.redVisibilityMiles,
        yellow: getRoute.req.yellowVisibilityMiles,
      },
      speedVal: getRoute.req.speedKts,
    }
    if (routes.length === 3) {
      //permitted routes limit reached, ask user to delete a route before editing
      errorStatus.addMessage(
        'You have reached the limit of routes allowed. Please delete a route before editing a saved route.',
        400,
        'error',
        true
      )
    } else {
      setRouteArray((prevArr) => [...prevArr, newRoute])
      dispatch(incrementRoute())
      dispatch(setWaypoints(getRoute.req.waypoints))
      if (Object.keys(getRoute.res).length > 0) {
        dispatch(setEvalApiResponse(getRoute.res))
      }
      
    }

    setShowSaveMenu(false)
  }

  useEffect(() => {
    setIsLoading(true)
    getAllSavedRoutes()
    setIsLoading(false)
  }, [])

  return (
    <div className="flex justify-center items-start">
      <div className="relative overflow-x-auto sm:rounded-lg w-full h-60 min-h-60 max-h-60">
        <table
          className="tranistion-all duration-1000 ease-in-out mt-6 w-full 
        text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
        >
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Route Name
              </th>
              <th scope="col" className="px-6 py-3">
                Route ID
              </th>
              <th scope="col" className="px-6 py-3">
                Edit
              </th>
              <th scope="col" className="px-6 py-3">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading && showNoRoutesMessage && (
              <div>You have no saved routes to display.</div>
            )}
            {isLoading &&
              !showNoRoutesMessage &&
              [1, 2, 3].map((el) => (
                <tr
                  key={el}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="w-4 p-4">
                    <div role="status" className="max-w-sm animate-pulse">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                    </div>
                  </td>
                  <td className="w-4 p-4">
                    <div role="status" className="max-w-sm animate-pulse">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                    </div>
                  </td>
                  <td className="w-4 p-4">
                    <div role="status" className="max-w-sm animate-pulse">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                    </div>
                  </td>
                </tr>
              ))}
            {!isLoading &&
              !showNoRoutesMessage &&
              routeList.map((route, idx) => (
                <tr
                  key={idx}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {route.label}
                  </th>
                  <td className="px-6 py-4">{route.id}</td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => editSelectedRoute(route.id)}
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      Edit
                    </button>
                  </td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => deleteSavedRoute(route.id)}
                      className="font-medium text-red-600 dark:text-red-500 hover:underline"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SavedRoutesMenu
