import { FeatureGroup, GeoJSON, Popup } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import { useState, useEffect, useRef, forwardRef, RefObject } from 'react'
import { LatLng } from 'leaflet'
import 'leaflet-draw/dist/leaflet.draw.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  setDropDown,
  setEvalApiResponse,
  setOptApiResponse,
  setWaypoints,
  setDrawnPolyline,
  incrementRoute,
} from '../RouteCast/routecastSlice'
import { RootState } from '../../../store'
import Toast, { toastPosition, toastType } from '../../Toast/Toast'

import { DrawComponent } from '../../DrawComponent/DrawComponent'

interface MapComponentProps {
  selectedSideNav: string
}

export const RouteCASTDraw = ({ selectedSideNav }: MapComponentProps) => {
  const drawnPolylineRef = useRef<any | null>(null)
  const selectedIndex = useSelector((state) => state.rcast.selectedIndex)

  const routes = useSelector((state) => state.rcast.routes)
  const evalApiResponse: any = useSelector(
    (state: RootState) => state.rcast.routes[selectedIndex].evalApiResponse
  )
  const optApiResponse: any = useSelector(
    (state: RootState) => state.rcast.routes[selectedIndex].optApiResponse
  )
  const showRC = useSelector((state: RootState) => state.rcast.showRouteCAST)
  // const waypoints = useSelector(
  //   (state: RootState) => state.rcast.routes[selectedIndex].waypoints
  // )

  const rc = ['RouteCast']
  const [drawn, setDrawn] = useState(false)
  const dispatch = useDispatch()
  const startDate = useSelector(
    (state: RootState) => state.rcast.routes[selectedIndex].startDate
  ) as string | null
  const offset = new Date().getTimezoneOffset() / 60
  const timezoneOffset = `${offset.toString().padStart(2, '0')}:00`
  const formattedStartDate = startDate
    ? startDate.replace('Z', '-' + timezoneOffset)
    : ''
  const waypoints = useSelector(
    (state) => state.rcast.routes[selectedIndex].waypoints
  )

  useEffect(() => {
    if (
      routes[selectedIndex].evalApiResponse ||
      routes[selectedIndex].optApiResponse
    ) {
      if (drawnPolylineRef.current) {
        drawnPolylineRef.current.remove()
      }
      clearDrawnPolyline()
    }
  }, [evalApiResponse, optApiResponse])

  useEffect(() => {
    if (!showRC) {
      dispatch(setEvalApiResponse(null))
      dispatch(setOptApiResponse(null))
      dispatch(setWaypoints(''))
      clearDrawnPolyline()
    }
  }, [showRC])

  const clearDrawnPolyline = () => {
    setDrawn(false)
    if (drawControlRef.current) {
      const drawControl = drawControlRef.current
      drawControl.leafletElement._toolbars.edit._modes.draw.handler.disable()
      drawControl.leafletElement._toolbars.edit._modes.remove.handler.enable()
      drawControl.leafletElement._toolbars.edit._modes.remove.removeAllLayers()
    }
  }

  // const _onCreate = (e: any) => {
  //   clearDrawnPolyline()
  //   dispatch(setEvalApiResponse(null))
  //   dispatch(setOptApiResponse(null))
  //   dispatch(setWaypoints(''))
  //   dispatch(setDrawnPolyline(e.layer))
  //   drawnPolylineRef.current = e.layer
  //   const latLngs = e.layer.getLatLngs()
  //   if (e.layerType === 'polygon') {
  //     const joinedArray = latLngs.map((array: any) =>
  //       array.map((latLng: any) => `${latLng.lat},${latLng.lng}`).join(', ')
  //     )
  //     const firstLat = latLngs[0][0].lat
  //     const firstLng = latLngs[0][0].lng
  //     const finalJoinedArray = `${joinedArray}, ${firstLat},${firstLng}`
  //     dispatch(setWaypoints(''))
  //     dispatch(setWaypoints(finalJoinedArray))
  //   } else {
  //     const wpoints = latLngs.map((latLng: LatLng) => [latLng.lat, latLng.lng])
  //     const waypointsString = wpoints
  //       .map((wt: string) => `${wt[0]}, ${wt[1]}`)
  //       .join(', ')
  //     dispatch(setWaypoints(''))
  //     dispatch(setWaypoints(waypointsString))
  //   }
  //   setDrawn(true)
  //   dispatch(setDropDown(true))
  // }
  // const _onDelete = (e: any) => {
  //   clearDrawnPolyline()
  //   dispatch(setEvalApiResponse(null))
  //   dispatch(setOptApiResponse(null))
  //   dispatch(setWaypoints(''))
    
  // }

  // const _onEdit = (e: any) => {
  //   const editedLayer = e.layers.getLayers()[0]
  //   const editedCoordinates = editedLayer.toGeoJSON().geometry.coordinates
  //   const flattenedCoordinates = editedCoordinates.flat(2)
  //   const reorderedCoordinates = []

  //   for (let i = 0; i < flattenedCoordinates.length; i += 2) {
  //     reorderedCoordinates.push(
  //       flattenedCoordinates[i + 1],
  //       flattenedCoordinates[i]
  //     )
  //   }
  //   const commaSeparatedString = reorderedCoordinates.join(', ')
  //   drawnPolylineRef.current = e.layer
  //   dispatch(setWaypoints(''))
  //   dispatch(setWaypoints(commaSeparatedString))
  //   setDrawn(true)
  //   dispatch(setDropDown(true))
  // }

  const drawControlRef = useRef<React.RefObject<any>>(null)

  useEffect(() => {
    drawnPolylineRef.current = routes[selectedIndex].drawnPath
  }, [selectedIndex])

  return (
    <>
      {showRC && <DrawComponent selectedSideNav={selectedSideNav} />}
      {routes.length > 0 &&
        routes.map(
          (route) =>
            route.evalApiResponse && (
              <FeatureGroup key={selectedIndex}>
                {route.evalApiResponse.features.map(
                  (feature1: any, index1: number) => (
                    <GeoJSON
                      key={`eval-${index1}-${feature1.properties.color}`}
                      data={feature1}
                      style={(geoJSONFeature1: any) => ({
                        color: geoJSONFeature1.properties.color,
                        weight: 10,
                      })}
                    >
                      <Popup
                        className="routecast-popup"
                        maxHeight={1200}
                        maxWidth={1200}
                      >
                        <div>
                          {feature1.properties.data.properties.wind_speed ? (
                            <>
                              <div className="flex items-center w-96 text-base">
                                <h2 className="font-semibold ">
                                  Start Time (UTC):&nbsp;&nbsp;{' '}
                                </h2>
                                <p>{formattedStartDate}</p>
                              </div>
                              <div className="flex items-center mt-[-28px] text-base">
                                <h2 className="font-semibold mr-2 ">
                                  Wind Speed:
                                </h2>
                                <p>
                                  {
                                    feature1.properties.data.properties
                                      .wind_speed
                                  }
                                </p>
                              </div>
                              <div className="flex items-center mt-[-28px] text-base">
                                <h2 className="font-semibold">
                                  Wind Direction: &nbsp;
                                </h2>
                                <p>
                                  {
                                    feature1.properties.data.properties
                                      .wind_direction
                                  }
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex items-center w-96 text-base">
                                <h2 className="font-semibold">
                                  Start Time (UTC):&nbsp;&nbsp;{' '}
                                </h2>
                                <p>{formattedStartDate}</p>
                              </div>
                              <div className="flex items-center mt-[-28px] text-base">
                                <h2 className="font-semibold mr-2 ">
                                  Ceiling:
                                </h2>
                                <p>
                                  {feature1.properties.data.properties.ceiling}
                                </p>
                              </div>
                              <div className="flex items-center mt-[-28px] text-base">
                                <h2 className="font-semibold">
                                  Visibility: &nbsp;
                                </h2>
                                <p>
                                  {
                                    feature1.properties.data.properties
                                      .visibility
                                  }
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                      </Popup>
                    </GeoJSON>
                  )
                )}
              </FeatureGroup>

              // route.optApiResponse && (
              //   <FeatureGroup>
              //     {optApiResponse.features.map((feature: any, index: number) => (
              //       <GeoJSON
              //         key={`opt-${index}-${feature.properties.color}`}
              //         data={feature}
              //         style={(geoJSONFeature: any) => ({
              //           color: geoJSONFeature.properties.color,
              //           weight: 10,
              //         })}
              //       >
              //         {' '}
              //         <Popup className="routecast-hover-popup">
              //           <div>
              //             {feature.properties.data.properties.wind_speed ? (
              //               <>
              //                 <div className="flex items-center w-96 text-sm">
              //                   <h2 className="font-semibold">
              //                     Start Time (UTC):&nbsp;&nbsp;{' '}
              //                   </h2>
              //                   <p>{formattedStartDate}</p>
              //                 </div>
              //                 <div className="flex items-center mt-[-28px] text-sm">
              //                   <h2 className="font-semibold mr-2 ">Wind Speed:</h2>
              //                   <p>{feature.properties.data.properties.wind_speed}</p>
              //                 </div>
              //                 <div className="flex items-center mt-[-28px] text-sm">
              //                   <h2 className="font-semibold">Wind Direction:</h2>
              //                   <p>
              //                     {feature.properties.data.properties.wind_direction}
              //                   </p>
              //                 </div>
              //               </>
              //             ) : (
              //               <>
              //                 <div className="flex items-center w-96 text-sm">
              //                   <h2 className="font-semibold">
              //                     Start Time (UTC):&nbsp;&nbsp;{' '}
              //                   </h2>
              //                   <p>{formattedStartDate}</p>
              //                 </div>
              //                 <div className="flex items-center mt-[-28px] text-sm">
              //                   <h2 className="font-semibold mr-2 ">Ceiling:</h2>
              //                   <p>{feature.properties.data.properties.ceiling}</p>
              //                 </div>
              //                 <div className="flex items-center mt-[-28px] text-sm">
              //                   <h2 className="font-semibold">Visibility: &nbsp;</h2>
              //                   <p>{feature.properties.data.properties.visibility}</p>
              //                 </div>
              //               </>
              //             )}
              //           </div>
              //         </Popup>
              //       </GeoJSON>
              //     ))}
              //   </FeatureGroup>
            )
        )}
      {drawn && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage="Please use the DropDown to begin RouteCAST evaluation"
          toastType={toastType.info}
        />
      )}
    </>
  )
}
