// import DEFAULT_DATA from '../../../interfaces/alerting'
import {
  addUpdateEvent,
  hideAlertSETTING,
  hideAlertSETTINGEdit,
  responseOk,
  setDropDownAlert,
  setLocation,
  setLocationType,
  showAlertSETTINGCreate,
  setEventCount,
} from '../AlertingSlice/AlertingSlice'
import { useSelector, useDispatch } from 'react-redux'
import store, { RootState } from '../../../store'
import { useEffect, useState } from 'react'
import chevronDown from '../../../assets/chev-up.svg'
import { TWS_API_URL } from '../../../environment/apis.config'
import moment from 'moment-timezone'
import { elements } from '../../../interfaces/alertingelements'
import useErrorStatus from '../../../hooks/UseErrorStatus'
import {
  setSelectedItemIds,
  setSelectedItems,
} from '../../../menus/SideNavigation/SideNavigationSlice'

interface ElementState {
  [key: string]: boolean
}
interface ElementInputValues {
  [key: string]: {
    yellow: string
    red: string
  }
}
const eventLocalData = {
  id: '',
  msg_type: 'Generate',
  message: {
    geometry: {
      coordinates: [],
      type: '',
    },
    type: 'Feature',
    properties: {
      creationTime: '',
      dynamicProperties: {},
      episodeStartTime: '',
      userProperties: {},
      eventName: '',
      startTime: '',
      externalEventName: '',
      endTime: '',
      episodeEndTime: '',
      notifiables: [],
      streams: [`${TWS_API_URL}/alerts`],
      adminContact: [
        'mailto:pavan.koundinya@truweathersolutions.com',
        'mailto:westy@cs.umass.edu',
      ],
      products: [],
    },
  },
}
const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}
const AlertSetting = (properties) => {
  const dispatch = useDispatch<any>()
  const [DefaultData, setDefaultData] = useState(eventLocalData)
  const showAlerts = useSelector(
    (state: RootState) => state.alert.showAlertSETTING
  )
  const eventCount = useSelector((state: RootState) => state.alert.eventCount)
  const errorStatus = useErrorStatus()
  const hazardMap = {
    WINDS_80M: {
      thU: [
        {
          value: 'mph',
          viewValue: 'mph',
        },
        {
          value: 'kph',
          viewValue: 'kph',
        },
        {
          value: 'kts',
          viewValue: 'kts',
        },
        {
          value: 'mps',
          viewValue: 'mps',
        },
        {
          value: 'fps',
          viewValue: 'fps',
        },
      ],
    },
    WINDS_RTMA: {
      thU: [
        {
          value: 'mph',
          viewValue: 'mph',
        },
        {
          value: 'kph',
          viewValue: 'kph',
        },
        {
          value: 'kts',
          viewValue: 'kts',
        },
        {
          value: 'mps',
          viewValue: 'mps',
        },
        {
          value: 'fps',
          viewValue: 'fps',
        },
      ],
    },
    WINDGUSTS_RTMA: {
      thU: [
        {
          value: 'mph',
          viewValue: 'mph',
        },
        {
          value: 'kph',
          viewValue: 'kph',
        },
        {
          value: 'kts',
          viewValue: 'kts',
        },
        {
          value: 'mps',
          viewValue: 'mps',
        },
        {
          value: 'fps',
          viewValue: 'fps',
        },
      ],
    },
    MRMS_PRECIP: {
      thU: [
        {
          value: 'inph',
          viewValue: 'inph',
        },
        {
          value: 'cmph',
          viewValue: 'cmph',
        },
      ],
    },
    TEMPERATURE: {
      thU: [
        {
          value: 'degrees_f',
          viewValue: 'degrees_f',
        },
        {
          value: 'degrees_c',
          viewValue: 'degrees_c',
        },
      ],
    },
    CEILINGS: {
      thU: [
        {
          value: 'feet',
          viewValue: 'feet',
        },
      ],
    },
    VISIBILITY: {
      thU: [
        {
          value: 'miles',
          viewValue: 'miles',
        },
        {
          value: 'SM',
          viewValue: 'SM',
        },
        {
          value: 'meters',
          viewValue: 'meters',
        },
        {
          value: 'kilometers',
          viewValue: 'kilometers',
        },
      ],
    },
  }
  const comparators = [
    { value: '>', viewValue: '>' },
    { value: '<', viewValue: '<' },
    { value: '=', viewValue: '=' },
    { value: '<=', viewValue: '<=' },
    { value: '>=', viewValue: '>=' },
  ]
  const distanceUnitOptions = {
    miles: 'miles',
    kilometers: 'kilometers',
    meters: 'meters',
  }
  const [selectedValues, setSelectedValues] = useState({})
  const [selectedValuesComparison, setSelectedValuesComparison] = useState({})
  const [selectedValuesDistanceUnits, setselectedValuesDistanceUnits] =
    useState({})
  const isPolygon = useSelector((state) => state.alert.isPolygon)
  const dropDown: any = useSelector(
    (state: RootState) => state.alert.setDropDownAlert
  )
  const handleDropdownChange = (elementId: string, value: string) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [elementId]: value,
    }))
    setThresholdUnitErrors((prevErrors) => ({ ...prevErrors, [elementId]: '' }))
  }
  const handleRedDropdownChange = (elementId, selectedValue) => {
    setSelectedValuesComparison((prevValues) => ({
      ...prevValues,
      [elementId]: selectedValue,
    }))
    setComparisonErrors((prevErrors) => ({ ...prevErrors, [elementId]: '' }))
  }
  const handleDistanceUnitChange = (elementId, selectedValue) => {
    setselectedValuesDistanceUnits((prevValues) => ({
      ...prevValues,
      [elementId]: selectedValue,
    }))
    setDistanceUnitErrors((prevErrors) => ({ ...prevErrors, [elementId]: '' }))
  }
  const [elementStates, setElementStates] = useState<ElementState>({})
  const [isInputEnabled, setIsInputEnabled] = useState(false)
  const [inputError] = useState('')
  const handleInputChange = (elementId, color, value) => {
    setElementInputValues((prevValues) => ({
      ...prevValues,
      [elementId]: {
        ...prevValues[elementId],
        [color]: value,
      },
    }))
    setThresholdErrors((prevErrors) => ({ ...prevErrors, [elementId]: '' }))
  }
  const [elementInputValuesDistance, setelementInputValuesDistance] = useState(
    {}
  )
  const handleInputChangeDistance = (elementId, value) => {
    setelementInputValuesDistance((prevValues) => ({
      ...prevValues,
      [elementId]: value,
    }))
    setDistancetErrors((prevErrors) => ({ ...prevErrors, [elementId]: '' }))
  }

  const [elementInputValues, setElementInputValues] =
    useState<ElementInputValues>(
      elements.reduce((acc, element) => {
        acc[element.id] = { yellow: '', red: '' }
        return acc
      }, {} as ElementInputValues)
    )
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target
    setIsInputEnabled(checked)

    if (
      //id === 'Lightning' ||
      id === 'NWS Tornado Warning' ||
      id === 'NWS Severe Thunderstorm Warning'
    ) {
      const yellowValue = checked ? '1' : ''
      setElementInputValues((prevValues) => ({
        ...prevValues,
        [id]: { yellow: yellowValue, red: '' },
      }))
    } else {
      setElementInputValues((prevValues) => ({
        ...prevValues,
        [id]: { yellow: '', red: '' },
      }))
    }
    setSelectedValuesComparison((prevValues) => ({
      ...prevValues,
      [id]: '',
    }))
    setelementInputValuesDistance((prevValues) => ({
      ...prevValues,
      [id]: '',
    }))
    setselectedValuesDistanceUnits((prevValues) => ({
      ...prevValues,
      [id]: '',
    }))
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [id]: '',
    }))
    setElementStates((prevState) => ({
      ...prevState,
      [id]: checked,
    }))
    if (!checked) {
      return
    }
  }
  const [showComponent, setShowComponent] = useState(false)

  useEffect(() => {
    if (dropDown && window.location.pathname === '/map') {
      setShowComponent(true)
    }
  })
  const [selectedOption, setSelectedOption] = useState('selectedLocation')
  const handleOptionChange = (event) => {
    if (event.target.value === 'mapArea') {
      errorStatus.addMessage(
        'Use the controls on the bottom left to draw an area for alerts inside shown bounding box',
        200,
        'success',
        false
      )
      setShowComponent(!showComponent)
      setTimeout(() => {
        dispatch(setDropDownAlert(false))
      }, 500)
    }
    setSelectedOption(event.target.value)
  }

  const defaultLocationCoordinates: any = useSelector(
    (state) => state.user.defaultLocation
  )

  const defaultLocation = useSelector(
    (state) => state.user.defaultLocation?.name
  )
  const [selectedLocation] = useState(defaultLocation)
  const finalJoinedArray = useSelector((state) => state.alert.setLocation)
  const typeLocation = useSelector((state) => state.alert.setLocationType)
  const editData = useSelector((state) => state.alert.editData)
  const editable = useSelector((state) => state.alert.showAlertSETTINGEdit)

  useEffect(() => {
    if (editable) {
      for (let i = 0; i < editData.length; i++) {
        if (editData[i].eventName == properties?.properties?.triggerEvent) {
          const updatedData = JSON.parse(JSON.stringify(editData[i]))
          const coordinate = updatedData.message.geometry.coordinates
          const typeOfC = updatedData.message.geometry.type
          const notifiable = updatedData.message.properties.notifiables
          const product = updatedData.message.properties.products
          setDefaultData((prevData) => ({
            ...prevData,
            id: updatedData.id,
            message: {
              ...prevData.message,
              geometry: {
                ...prevData.geometry,
                coordinates: coordinate,
                type: typeOfC,
              },
              properties: {
                ...prevData.message.properties,
                externalEventName: updatedData.eventName,
                eventName: updatedData.eventName,
                startTime: updatedData.startTime,
                creationTime: updatedData.startTime,
                episodeStartTime: updatedData.startTime,
                endTime: updatedData.endTime,
                episodeEndTime: updatedData.endTime,
                notifiables: notifiable,
                products: product,
              },
            },
          }))
          break
        }
      }
    }
  }, [editable, editData, properties?.properties?.triggerEvent])
  const storedEmail = useSelector((state) => state.user.email)
  const [emailInputs, setEmailInputs] = useState([storedEmail, ''])
  const handleEmailChange = (e, index) => {
    const newEmail = e.target.value
    setEmailInputs((prevEmails) => {
      const updatedEmails = [...prevEmails]
      updatedEmails[index] = newEmail
      return updatedEmails
    })
  }
  const handleAddEmailInput = () => {
    setEmailInputs([...emailInputs, ''])
  }
  const handleDeleteEmailInput = (index) => {
    setEmailInputs(emailInputs.filter((_, i) => i !== index))
  }
  const [eventName, setEventName] = useState('')
  const [startTime, setStartTime] = useState(
    moment.tz(moment(), moment.tz.guess())
  )
  const [endTime, setEndTime] = useState(moment.tz(moment(), moment.tz.guess()))
  const updateStatesFromProducts = (products) => {
    products.forEach((product) => {
      const { hazard, parameters } = product
      const key = elements.find((element) => element.edit === hazard)?.id
      if (key) {
        const {
          threshold,
          thresholdUnits,
          comparison,
          distance,
          distanceUnits,
        } = parameters[0]
        setElementInputValues((prevValues) => ({
          ...prevValues,
          [key]: {
            yellow: threshold,
            red: '',
          },
        }))

        setSelectedValues((prevSelectedValues) => ({
          ...prevSelectedValues,
          [key]: thresholdUnits,
        }))

        setSelectedValuesComparison((prevSelectedValuesComparison) => ({
          ...prevSelectedValuesComparison,
          [key]: comparison,
        }))
        setElementStates((prevState) => ({
          ...prevState,
          [key]: true,
        }))
        setelementInputValuesDistance((prevValues) => ({
          ...prevValues,
          [key]: distance,
        }))
        setselectedValuesDistanceUnits((prevValues) => ({
          ...prevValues,
          [key]: distanceUnits,
        }))
      }
    })
  }
  useEffect(() => {
    if (editable) {
      setEventName(DefaultData.message.properties.eventName)
      setStartTime(moment(DefaultData.message.properties.startTime))
      setEndTime(moment(DefaultData.message.properties.endTime))
      const notifiables = DefaultData.message.properties.notifiables
      const notifiablesWithoutFirstElement = notifiables
      let updatedEmailInputs
      if (notifiablesWithoutFirstElement.length === 0) {
        updatedEmailInputs = ['', '']
      } else if (notifiablesWithoutFirstElement.length === 1) {
        const emailWithoutMailto = notifiablesWithoutFirstElement[0].replace(
          'mailto:',
          ''
        )
        updatedEmailInputs = [emailWithoutMailto, '']
      } else {
        updatedEmailInputs = notifiablesWithoutFirstElement.map((email) =>
          email.replace('mailto:', '')
        )
      }
      setEmailInputs(updatedEmailInputs)
      const product = DefaultData.message.properties.products
      updateStatesFromProducts(product)
      dispatch(setLocation(''))
      dispatch(setLocationType(''))
      dispatch(
        setLocation(JSON.stringify(DefaultData.message.geometry.coordinates))
      )
      dispatch(setLocationType(DefaultData.message.geometry.type))
      setSelectedOption('mapArea')
    }
  }, [DefaultData, editable])
  const [eventNameError, setEventNameError] = useState('')
  const [coordinatesError, setCoordinatesError] = useState('')
  const [startTimeError, setStartTimeError] = useState('')
  const [endTimeError] = useState('')
  const [checkboxError, setCheckboxError] = useState('')
  const [editLocationMessage, seteditLocationMessage] = useState(
    'After creating an event in a specific area, changing the location is not possible'
  )
  const [thresholdErrors, setThresholdErrors] = useState({})
  const [comparisonErrors, setComparisonErrors] = useState({})
  const [thresholdUnitErrors, setThresholdUnitErrors] = useState({})
  const [distanceErrors, setDistancetErrors] = useState({})
  const [distanceUnitErrors, setDistanceUnitErrors] = useState({})
  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(true)
  const handleButtonClick = () => {
    dispatch(setDropDownAlert(false))
    setShowComponent(!showComponent)
    setTimeout(() => {
      //   dispatch(setDropDown(!showComponent));
    }, 500)
  }
  const outOfBound: any = useSelector(
    (state: RootState) => state.alert.outOfBounds
  )
  const [activeStep, setActiveStep] = useState(0)
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      let isValid = true
      const atLeastOneCheckboxSelected = Object.values(elementStates).some(
        (isChecked) => isChecked
      )
      if (!atLeastOneCheckboxSelected) {
        setCheckboxError('At least one hazard must be selected. ')
        errorStatus.addMessage(
          'At least one hazard must be selected.',
          200,
          'error',
          false
        )

        isValid = false
      }
      if (isValid) {
        setCheckboxError('')
      }
      if (!isValid) {
        return
      }
      const hasErrors = Object.keys(elementStates).some((elementId) => {
        let hasError = false
        if (
          elementId != 'Lightning' &&
          elementId != 'NWS Tornado Warning' &&
          elementId != 'NWS Severe Thunderstorm Warning' &&
          elementStates[elementId]
        ) {
          if (!elementInputValues[elementId].yellow) {
            setThresholdErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: 'Threshold is required.',
            }))
            hasError = true
            errorStatus.addMessage('Threshold is required', 200, 'error', false)
          } else if (isNaN(elementInputValues[elementId].yellow)) {
            setThresholdErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: 'Threshold must be a number.',
            }))
            hasError = true
            errorStatus.addMessage(
              'Threshold must be a number',
              200,
              'error',
              false
            )
          } else if (
            elementInputValues[elementId].yellow.split('.')[1]?.length > 2
          ) {
            setThresholdErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: 'We only accept decimal till 2 places',
            }))
            hasError = true
            errorStatus.addMessage(
              'We only accept decimal till 2 places',
              200,
              'error',
              false
            )
          } else {
            setThresholdErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: '',
            }))
          }
          if (!selectedValues[elementId]) {
            setThresholdUnitErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: 'Threshold Units are required.',
            }))
            hasError = true
            errorStatus.addMessage(
              'Threshold Units are required',
              200,
              'error',
              false
            )
          } else {
            setThresholdUnitErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: '',
            }))
          }
          if (!selectedValuesComparison[elementId]) {
            setComparisonErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: 'Comparison is required.',
            }))
            hasError = true
            errorStatus.addMessage(
              'Comparison is required.',
              200,
              'error',
              false
            )
          } else {
            setComparisonErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: '',
            }))
          }
          if (selectedOption == 'mapArea' && isPolygon) {
            setDistanceUnitErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: '',
            }))
          } else if (!selectedValuesDistanceUnits[elementId]) {
            setDistanceUnitErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: 'Distance Units is required.',
            }))
            errorStatus.addMessage(
              'Distance Units is required.',
              200,
              'error',
              false
            )
            hasError = true
          } else {
            setDistanceUnitErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: '',
            }))
          }
          if (selectedOption == 'mapArea' && isPolygon) {
            setDistancetErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: '',
            }))
          } else if (!elementInputValuesDistance[elementId]) {
            setDistancetErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: 'Distance is required.',
            }))
            hasError = true
            errorStatus.addMessage('Distance is required.', 200, 'error', false)
          } else {
            setDistancetErrors((prevErrors) => ({
              ...prevErrors,
              [elementId]: '',
            }))
          }
        } else {
          if (elementStates[elementId]) {
            if (selectedOption == 'mapArea' && isPolygon) {
              setDistanceUnitErrors((prevErrors) => ({
                ...prevErrors,
                [elementId]: '',
              }))
            } else if (!selectedValuesDistanceUnits[elementId]) {
              setDistanceUnitErrors((prevErrors) => ({
                ...prevErrors,
                [elementId]: 'Distance Units is required.',
              }))
              hasError = true
              errorStatus.addMessage(
                'Distance Units is required.',
                200,
                'error',
                false
              )
            } else {
              setDistanceUnitErrors((prevErrors) => ({
                ...prevErrors,
                [elementId]: '',
              }))
            }
            if (selectedOption == 'mapArea' && isPolygon) {
              setDistancetErrors((prevErrors) => ({
                ...prevErrors,
                [elementId]: '',
              }))
            } else if (!elementInputValuesDistance[elementId]) {
              setDistancetErrors((prevErrors) => ({
                ...prevErrors,
                [elementId]: 'Distance is required.',
              }))
              hasError = true
              errorStatus.addMessage(
                'Distance is required.',
                200,
                'error',
                false
              )
            } else {
              setDistancetErrors((prevErrors) => ({
                ...prevErrors,
                [elementId]: '',
              }))
            }
          }
        }
        return hasError
      })

      if (hasErrors) {
        return
      }
      const formattedStartTime = new Date(startTime)
        .toISOString()
        .replace(/\.\d{3}Z$/, 'Z')
      const formattedEndTime = new Date(endTime)
        .toISOString()
        .replace(/\.\d{3}Z$/, 'Z')
      const creationTime = new Date().toISOString().replace(/\.\d{3}Z$/, 'Z')
      const notification = emailInputs
        .filter((email) => email !== '')
        .map((email) => {
          const emailRegex =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
          if (emailRegex.test(email)) {
            return `mailto:${email}`
          }
          return email
        })

      const products = Object.keys(elementInputValues)
        .filter((key) => {
          const element = elementInputValues[key]
          return element.yellow !== '' || element.red !== ''
        })
        .map((key) => {
          const element = elementInputValues[key]
          const hazard = elements.find((elem) => elem.id === key)?.value || ''
          const thresholdUnits = selectedValues[key] || ''
          const comparison = selectedValuesComparison[key] || ''
          let distance = ''
          let distanceUnits = ''
          if (selectedOption == 'mapArea' && isPolygon) {
            distance = '0'
            distanceUnits = 'miles'
          } else {
            distance = elementInputValuesDistance[key] || '0'
            distanceUnits = selectedValuesDistanceUnits[key] || 'miles'
          }
          if (
            //key === 'Lightning' ||
            key === 'NWS Tornado Warning' ||
            key === 'NWS Severe Thunderstorm Warning'
          ) {
            return {
              hazard: hazard,
              parameters: [
                {
                  distance: distance,
                  distanceUnits: distanceUnits,
                },
              ],
            }
          }
          return {
            hazard: hazard,
            parameters: [
              {
                threshold: element.yellow !== '' ? element.yellow : element.red,
                thresholdUnits: thresholdUnits,
                distance: distance,
                distanceUnits: distanceUnits,
                comparison: comparison,
              },
            ],
          }
        })

      let coordinates = []
      let typeOfLocation = ''

      if (selectedOption == 'selectedLocation') {
        coordinates[1] = parseFloat(defaultLocationCoordinates.latitude)
        coordinates[0] = parseFloat(defaultLocationCoordinates.longitude)
        typeOfLocation = 'Point'
      } else {
        coordinates = JSON.parse(finalJoinedArray)
        typeOfLocation = typeLocation
      }
      const updatedEventLocalData = {
        ...eventLocalData,
        message: {
          ...eventLocalData.message,
          geometry: {
            coordinates: coordinates,
            type: typeOfLocation,
          },
          location: {
            lat: defaultLocationCoordinates.latitude,
            long: defaultLocationCoordinates.longitude,
            id: defaultLocation,
          },
          properties: {
            ...eventLocalData.message.properties,
            externalEventName: eventName,
            eventName: eventName,
            startTime: formattedStartTime,
            creationTime: creationTime,
            episodeStartTime: formattedStartTime,
            endTime: formattedEndTime,
            episodeEndTime: formattedEndTime,
            notifiables: notification,
            products: products,
          },
        },
      }
      setShowComponent(false)
      dispatch(setDropDownAlert(false))
      addUpdateEvent(updatedEventLocalData, editable)
        .then((response) => {
          if (response?.eventID || editable) {
            let newSelection = [...store.getState().sideNav.selectedItemIds]
            if (newSelection.includes('alerts-create')) {
              newSelection.splice(newSelection.indexOf('alerts-create'), 1)
              dispatch(setSelectedItemIds(newSelection))
            }
            newSelection = [...store.getState().sideNav.selectedItems]
            if (newSelection.includes('Create an Alert')) {
              newSelection.splice(newSelection.indexOf('Create an Alert'), 1)
              dispatch(setSelectedItems(newSelection))
            }
            dispatch(showAlertSETTINGCreate(false))
            dispatch(hideAlertSETTINGEdit())
            dispatch(hideAlertSETTING())
            dispatch(
              responseOk({
                code: 200,
                msg: 'Event Created Successfully!',
                valid: true,
              })
            )
            dispatch(setEventCount(eventCount + 1))
          } else {
            dispatch(
              responseOk({ code: 403, msg: response.Message, valid: true })
            )
          }
        })
        .catch((error) => {
          console.error(error)
        })
      // return
    } else if (activeStep === 0) {
      let isValid = true
      if (!eventName.trim()) {
        setEventNameError('Event Name is required. ')
        isValid = false
      }
      if (isValid) {
        setEventNameError('')
      }
      if (new Date(startTime) >= new Date(endTime)) {
        setStartTimeError('Start Time must be earlier than End Time. ')
        isValid = false
      }
      if (isValid) {
        setStartTimeError('')
      }
      if (!isValid) {
        return
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    } else if (activeStep === steps.length - 1) {
      // setActiveStep((prevActiveStep) => prevActiveStep + 1)
    } else if (activeStep === 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    } else if (activeStep === 1) {
      let isValid = true

      if (selectedOption == 'mapArea' && finalJoinedArray == '') {
        setCoordinatesError('Geometric Coordiantes are required')
        isValid = false
      }
      if (selectedOption == 'mapArea' && outOfBound) {
        setCoordinatesError('Out of bounds')
        isValid = false
      }
      if (isValid) {
        setCoordinatesError('')
      }
      if (!isValid) {
        return
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const steps = [
    {
      icon: (
        <span
          className={`flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12 dark:bg-gray-700 shrink-0 ${
            activeStep >= 0
              ? 'text-gray-900 dark:text-gray-900'
              : 'text-gray-500 dark:text-gray-900'
          }`}
        >
          <svg
            className="w-4 h-4 lg:w-5 lg:h-5 dark:text-gray-100"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 16"
          >
            <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
          </svg>
        </span>
      ),
      label: 'Event Name',
    },
    {
      icon: (
        <span
          className={`flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12 dark:bg-gray-700 shrink-0 ${
            activeStep >= 3
              ? 'text-gray-900 dark:text-gray-900'
              : 'text-gray-500 dark:text-gray-900'
          }`}
        >
          <svg
            className="w-6 h-6dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill={activeStep >= 1 ? '#000000' : '#9E9E9E'}
            viewBox="0 0 16 20"
          >
            <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
          </svg>
        </span>
      ),
      label: 'Location',
    },
    {
      icon: (
        <span
          className={`flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12 dark:bg-gray-700 shrink-0 ${
            activeStep >= 2
              ? 'text-gray-900 dark:text-gray-900'
              : 'text-gray-500 dark:text-gray-900'
          }`}
        >
          <svg
            className="w-6 h-6 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 20"
          >
            <path d="M16 0H4a2 2 0 0 0-2 2v1H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4.5a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM13.929 17H7.071a.5.5 0 0 1-.5-.5 3.935 3.935 0 1 1 7.858 0 .5.5 0 0 1-.5.5Z" />
          </svg>
        </span>
      ),
      label: 'Notify',
    },
    {
      icon: (
        <span
          className={`flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12 dark:bg-gray-700 shrink-0 ${
            activeStep >= 1
              ? 'text-gray-900 dark:text-gray-900'
              : 'text-gray-500 dark:text-gray-900'
          }`}
        >
          <svg
            fill={activeStep >= 3 ? '#000000' : '#9E9E9E'}
            width="28"
            height="25"
            viewBox="0 0 28 25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15.25 15.5H12.75V9.25H15.25M15.25 20.5H12.75V18H15.25M0.25 24.25H27.75L14 0.5L0.25 24.25Z" />
          </svg>
        </span>
      ),
      label: 'Hazard',
    },
  ]

  const hancleCancel = () => {
    let newSelection = [...store.getState().sideNav.selectedItemIds]
    if (newSelection.includes('alerts-create')) {
      newSelection.splice(newSelection.indexOf('alerts-create'), 1)
      dispatch(setSelectedItemIds(newSelection))
    }
    newSelection = [...store.getState().sideNav.selectedItems]
    if (newSelection.includes('Create an Alert')) {
      newSelection.splice(newSelection.indexOf('Create an Alert'), 1)
      dispatch(setSelectedItems(newSelection))
    }
    dispatch(showAlertSETTINGCreate(false))
    dispatch(hideAlertSETTINGEdit())
    dispatch(hideAlertSETTING())
  }

  useEffect(() => {
    if (window.location.pathname === '/' || window.location.pathname === '/map')
      setShowAlertDialog(true)
    else setShowAlertDialog(false)
  }, [window.location.pathname])

  return (
    <>
      {showAlerts && (
        <div
          className={`${showAlertDialog ? 'block' : 'hidden'} items-center`}
          style={{ marginLeft: '200px' }}
        >
          <div
            className="absolute top-15 flex items-center justify-center"
            style={{ width: '600px', left: '35%' }}
          >
            <div className="flex flex-col" >
              {showComponent && (
                <div className="flex flex-col w-full justify-start bg-white mb-4 pb-7 pl-16 pt-12 z-[99999]">
                  <div className="items-center mt-1 mr-10">
                    <ol className="flex items-center w-full">
                      {steps.map((step, index) => (
                        <li
                          key={index}
                          className={`relative flex w-40 h-24 flex-col items-center justify-center text-center ${
                            activeStep === index
                              ? 'text-gray-900 dark:text-gray-200 border-b border-white border-2 inline-block dark:border-gray-500'
                              : activeStep > index
                              ? 'text-gray-900 dark:text-gray-900 border-b border-white border-2 inline-block dark:border-gray-500'
                              : 'text-gray-400 dark:text-gray-400 border-b border-white border-2 inline-block dark:border-gray-500'
                          } ${index !== 0 ? 'ml-8' : ''}`}
                        >
                          {step.icon}
                          <span className="mt-2 text-sm">{step.label}</span>
                        </li>
                      ))}
                    </ol>
                    {activeStep === 0 && (
                      <div
                        className="stepper-step mb-32 mt-16 ml-14"
                        style={{ marginRight: '15px' }}
                      >
                        <div className="flex items-center ml-4">
                          <label
                            htmlFor="event-name-input"
                            className="text-md font-medium mr-2"
                          >
                            Event Name
                          </label>
                          <input
                            type="text"
                            id="event-name-input"
                            value={eventName}
                            onChange={(event) =>
                              setEventName(event.target.value)
                            }
                            className="p-1 text-md w-64 h-8  border border-gray-300 rounded-md focus:outline-none focus:border-gray-900"
                            disabled={editable}
                          />
                        </div>
                        <div className="flex items-center mt-10 ml-4">
                          <label
                            htmlFor="start-time-input"
                            className="text-md font-medium mr-4"
                          >
                            Start Time:
                          </label>
                          <input
                            type="datetime-local"
                            id="start-time-input"
                            value={startTime.format('YYYY-MM-DDTHH:mm')}
                            onChange={(e) =>
                              setStartTime(
                                moment(e.target.value, 'YYYY-MM-DDTHH:mm')
                              )
                            }
                            className="p-1 text-md w-64 h-8 border border-gray-300 rounded-md focus:outline-none focus:border-gray-900"
                          />
                        </div>
                        <div className="flex items-center mt-10 ml-4">
                          <label
                            htmlFor="end-time-input"
                            className="text-md font-medium mr-4"
                          >
                            End Time:
                          </label>
                          <input
                            type="datetime-local"
                            id="end-time-input"
                            value={endTime.format('YYYY-MM-DDTHH:mm')}
                            onChange={(e) =>
                              setEndTime(
                                moment(e.target.value, 'YYYY-MM-DDTHH:mm')
                              )
                            }
                            className="p-1 text-md w-64 h-8 ml-1 border border-gray-300 rounded-md focus:outline-none focus:border-gray-900"
                          />
                        </div>
                      </div>
                    )}
                    {activeStep === 1 && (
                      <>
                        <div className="stepper-step mb-24 ml-14">
                          <h6
                            className="font-bold text-md ml-60"
                            style={{ marginTop: '50px' }}
                          >
                            Show Alert for
                          </h6>
                          <div className="flex items-center mt-12">
                            <label
                              htmlFor="map-area-radio"
                              className={`mr-2 ml-4 relative inline-block h-4 w-4 border-2 border-gray-900 square-md ${
                                selectedOption === 'mapArea'
                                  ? 'bg-black'
                                  : 'bg-white'
                              }`}
                              style={{
                                marginTop: '10px',
                                borderColor: editable ? '#ccc' : 'initial',
                                backgroundColor: editable ? '#eee' : 'initial',
                                cursor: editable ? 'not-allowed' : 'pointer',
                              }}
                            >
                              <input
                                type="radio"
                                id="map-area-radio"
                                name="alertOption"
                                value="mapArea"
                                className="appearance-none opacity-0 absolute h-4 w-4"
                                disabled={editable}
                                onChange={handleOptionChange}
                                checked={selectedOption === 'mapArea'}
                              />
                              {selectedOption === 'mapArea' && (
                                <>
                                  <svg
                                    className="absolute top-0 left-0 w-3 h-3 text-gray-900 pointer-events-none bg-white"
                                    viewBox="0 02 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M4 11L10 16L18 8"
                                      stroke={
                                        editable ? '#ccc' : 'currentColor'
                                      }
                                      strokeWidth="3"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </>
                              )}
                            </label>
                            <label
                              htmlFor="map-area-radio"
                              className="flex items-center justify-center text-md ml-2 mt-3"
                            >
                              Map Area
                            </label>
                          </div>
                          <div className="flex items-center mt-10">
                            <label
                              htmlFor="map-area-radio"
                              className={`mr-2 ml-4 relative inline-block h-4 w-4 border-2 border-gray-900 square-md ${
                                selectedOption === 'selectedLocation'
                                  ? 'bg-black'
                                  : 'bg-white'
                              }`}
                              style={{
                                marginTop: '10px',
                                borderColor: editable ? '#ccc' : 'initial',
                                backgroundColor: editable ? '#eee' : 'initial',
                                cursor: editable ? 'not-allowed' : 'pointer',
                              }}
                            >
                              <input
                                type="radio"
                                id="selected-location-radio"
                                name="alertOption"
                                value="selectedLocation"
                                className="appearance-none opacity-0 absolute h-4 w-4"
                                onChange={handleOptionChange}
                                disabled={editable}
                                checked={selectedOption === 'selectedLocation'}
                              />
                              {selectedOption === 'selectedLocation' && (
                                <svg
                                  className="absolute top-0 left-0 w-3 h-3 text-gray-900 pointer-events-none bg-white"
                                  viewBox="0 02 22 20"
                                  fill="none"
                                >
                                  <path
                                    d="M4 11L10 16L18 8"
                                    stroke={editable ? '#ccc' : 'currentColor'}
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </label>
                            <label
                              htmlFor="selected-location-radio"
                              className="text-md ml-2 mt-3"
                            >
                              Selected Location
                            </label>
                          </div>
                          {selectedOption === 'selectedLocation' && (
                            <div className="mt-4 ml-6">
                              <h2>Selected Location: {selectedLocation}</h2>
                            </div>
                          )}
                          {selectedOption == 'mapArea' && outOfBound && (
                            <span style={{ color: 'red' }}>
                              Alert area out of bounds.
                            </span>
                          )}
                        </div>
                      </>
                    )}
                    {activeStep === 2 && (
                      <>
                        <h6 className="font-bold flex items-center justify-center text-md mt-12 ">
                          Notification
                        </h6>
                        <div className="stepper-step-2 ml-14">
                          {emailInputs.map((email, index) => (
                            <div
                              key={index}
                              className="email-input mt-12 mb-11 "
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <div className="email-input-box">
                                <input
                                  type="email"
                                  value={email}
                                  onChange={(e) => handleEmailChange(e, index)}
                                  className={
                                    !validateEmail(email)
                                      ? 'invalid p-1 border border-gray-300 rounded-md focus:outline-none focus:border-gray-900'
                                      : 'p-1 border border-gray-300 rounded-md focus:outline-none focus:border-gray-900'
                                  }
                                />
                              </div>
                              {
                                <div className="delete-email">
                                  <button
                                    className="delete-button ml-2"
                                    onClick={() =>
                                      handleDeleteEmailInput(index)
                                    }
                                  >
                                    <svg
                                      className="w-[24px] h-[24px] text-gray-900 dark:text-white"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                                    </svg>
                                  </button>
                                </div>
                              }
                            </div>
                          ))}
                          <button
                            onClick={handleAddEmailInput}
                            className={`add-icon-button ${
                              emailInputs.length >= 3 ? 'disabled' : ''
                            } mb-16`}
                            disabled={emailInputs.length >= 3}
                          >
                            <svg
                              className="w-[24px] h-[24px] text-gray-900 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.546.5a9.5 9.5 0 1 0 9.5 9.5 9.51 9.51 0 0 0-9.5-9.5ZM13.788 11h-3.242v3.242a1 1 0 1 1-2 0V11H5.304a1 1 0 0 1 0-2h3.242V5.758a1 1 0 0 1 2 0V9h3.242a1 1 0 1 1 0 2Z" />
                            </svg>
                          </button>
                        </div>
                      </>
                    )}
                    {activeStep === 3 && (
                      <div className="stepper-step mb-7 mt-7">
                        {inputError && (
                          <div
                            style={{
                              color: 'red',
                              marginBottom: '1px',
                              marginLeft: '4px',
                            }}
                          >
                            {inputError}
                          </div>
                        )}
                        <div>
                          {elements.map((element) => (
                            <div
                              className="flex items-center mt-2 ml-0"
                              key={element.id}
                            >
                              <label
                                htmlFor={`${element.id}`}
                                className={`mr-4 relative inline-block h-4 w-4 border-2 border-gray-900 square-md ${
                                  elementStates[`${element.id}`]
                                    ? 'bg-black'
                                    : 'bg-white'
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  id={`${element.id}`}
                                  checked={elementStates[element.id]}
                                  className="appearance-none opacity-0 absolute h-4 w-4"
                                  onChange={handleCheckboxChange}
                                />
                                {elementStates[`${element.id}`] && (
                                  <svg
                                    className="absolute top-0 left-0 w-3 h-3 text-gray-900 pointer-events-none bg-white"
                                    viewBox="0 02 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M4 11L10 16L18 8"
                                      stroke="currentColor"
                                      strokeWidth="3"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </label>
                              <div className="flex items-center">
                                <div className="w-28 mr-5">
                                  <label
                                    htmlFor={`${element.id}`}
                                    className="text-md whitespace-nowrap"
                                  >
                                    {element.label}
                                  </label>
                                </div>
                                {//element.id !== 'Lightning' &&
                                  element.id !== 'NWS Tornado Warning' &&
                                  element.id !==
                                    'NWS Severe Thunderstorm Warning' && (
                                    <>
                                      <input
                                        type="text"
                                        id={`${element.id}-input-yellow`}
                                        placeholder="Threshold"
                                        value={
                                          elementInputValues[element.id].yellow
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            element.id,
                                            'yellow',
                                            e.target.value
                                          )
                                        }
                                        className={`bg-white-400 p-1 text-sm w-20 h-5 ml-7 mr-2 border-2 border-gray-500 ${
                                          elementStates[`${element.id}`] &&
                                          isInputEnabled
                                            ? 'bg-white-400'
                                            : 'bg-white-400'
                                        } text-black-400 text-center placeholder-gray ${
                                          thresholdErrors[element.id]
                                            ? 'border-red-500 text-red-500'
                                            : ''
                                        }`}
                                        disabled={
                                          !elementStates[`${element.id}`] ||
                                          (!isInputEnabled &&
                                            !elementStates[`${element.id}`])
                                        }
                                      />
                                      <select
                                        id={`${element.id}-dropdown-red`}
                                        name="comparison"
                                        className={`ml-1 text-sm border-2 border-gray-500 placeholder-gray ${
                                          comparisonErrors[element.id]
                                            ? 'border-red-500 text-red-500'
                                            : ''
                                        }`}
                                        style={{ width: '100px' }}
                                        onChange={(e) =>
                                          handleRedDropdownChange(
                                            element.id,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          selectedValuesComparison[
                                            element.id
                                          ] || ''
                                        }
                                        disabled={
                                          !elementStates[`${element.id}`] ||
                                          (!isInputEnabled &&
                                            !elementStates[`${element.id}`])
                                        }
                                      >
                                        <option value="" disabled hidden>
                                          Comparison
                                        </option>
                                        {comparators.map((comparator) => (
                                          <option
                                            key={comparator.value}
                                            value={comparator.value}
                                            className={
                                              selectedValuesComparison[
                                                element.id
                                              ] === comparator.value
                                                ? 'selected'
                                                : ''
                                            }
                                          >
                                            {comparator.value}
                                          </option>
                                        ))}
                                      </select>
                                      <select
                                        id={`${element.id}-dropdown`}
                                        name="Threshold Units"
                                        className={`ml-2 text-sm placeholder-gray border-2 border-gray-500 ${
                                          thresholdUnitErrors[element.id]
                                            ? 'border-red-500 text-red-500'
                                            : ''
                                        }`}
                                        style={{ width: '100px' }}
                                        onChange={(e) =>
                                          handleDropdownChange(
                                            element.id,
                                            e.target.value
                                          )
                                        }
                                        value={selectedValues[element.id] || ''}
                                        disabled={
                                          !elementStates[`${element.id}`] ||
                                          (!isInputEnabled &&
                                            !elementStates[`${element.id}`])
                                        }
                                      >
                                        <option value="" disabled hidden>
                                          Threshold Units
                                        </option>
                                        {hazardMap[element.value].thU.map(
                                          (option) => (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                              className={
                                                selectedValues[element.id] ===
                                                option.value
                                                  ? 'selected '
                                                  : ''
                                              }
                                            >
                                              {option.viewValue}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <input
                                        type="text"
                                        id={`${element.id}-input-distance`}
                                        placeholder="Distance"
                                        value={
                                          elementInputValuesDistance[element.id]
                                        }
                                        onChange={(e) =>
                                          handleInputChangeDistance(
                                            element.id,
                                            e.target.value
                                          )
                                        }
                                        className={`p-1 text-sm w-28 h-5 ml-3 mr-2 border-2 ${
                                          elementStates[`${element.id}`] &&
                                          isInputEnabled
                                            ? 'bg-white-400'
                                            : 'bg-white-400'
                                        } text-black-400 text-center placeholder-gray ${
                                          distanceErrors[element.id]
                                            ? 'border-red-500 text-red-500'
                                            : ''
                                        } ${
                                          selectedOption === 'mapArea' &&
                                          isPolygon
                                            ? 'border-0'
                                            : 'border-gray-500'
                                        }`}
                                        disabled={
                                          !elementStates[`${element.id}`] ||
                                          (!isInputEnabled &&
                                            !elementStates[`${element.id}`]) ||
                                          (selectedOption === 'mapArea' &&
                                            isPolygon)
                                        }
                                      />
                                      <select
                                        id={`${element.id}-dropdown-distance-units`}
                                        name="Distance Units"
                                        className={`ml-1 text-sm placeholder-gray ${
                                          distanceUnitErrors[element.id]
                                            ? 'border-red-500 text-red-500'
                                            : ''
                                        } ${
                                          selectedOption === 'mapArea' &&
                                          isPolygon
                                            ? 'border-0'
                                            : 'border-2 border-gray-500'
                                        }`}
                                        style={{ width: '100px' }}
                                        onChange={(e) =>
                                          handleDistanceUnitChange(
                                            element.id,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          selectedValuesDistanceUnits[
                                            element.id
                                          ] || ''
                                        }
                                        disabled={
                                          !elementStates[`${element.id}`] ||
                                          (!isInputEnabled &&
                                            !elementStates[`${element.id}`]) ||
                                          (selectedOption === 'mapArea' &&
                                            isPolygon)
                                        }
                                      >
                                        <option value="" disabled hidden>
                                          Distance Units
                                        </option>
                                        {Object.entries(
                                          distanceUnitOptions
                                        ).map(([unit, label]) => (
                                          <option key={unit} value={unit}>
                                            {label}
                                          </option>
                                        ))}
                                      </select>
                                    </>
                                  )}
                                {//element.id === 'Lightning' ||
                                element.id === 'NWS Tornado Warning' ||
                                element.id ===
                                  'NWS Severe Thunderstorm Warning' ? (
                                  <>
                                    <input
                                      type="text"
                                      id={`${element.id}-input-distance`}
                                      placeholder="Distance"
                                      value={
                                        elementInputValuesDistance[element.id]
                                      }
                                      onChange={(e) =>
                                        handleInputChangeDistance(
                                          element.id,
                                          e.target.value
                                        )
                                      }
                                      className={`bg-white-400 p-1 text-sm w-28 h-5 ml-10 mr-3 border-2 text-center placeholder-gray ${
                                        distanceUnitErrors[element.id]
                                          ? 'border-red-500 text-red-500'
                                          : ''
                                      } ${
                                        selectedOption === 'mapArea' &&
                                        isPolygon
                                          ? 'border-0'
                                          : 'border-2 border-gray-500'
                                      }`}
                                      style={{ marginLeft: '340px' }}
                                      disabled={
                                        !elementStates[`${element.id}`] ||
                                        (!isInputEnabled &&
                                          !elementStates[`${element.id}`]) ||
                                        (selectedOption === 'mapArea' &&
                                          isPolygon)
                                      }
                                    />
                                    <select
                                      id={`${element.id}-dropdown-distance-units`}
                                      name="Distance Units"
                                      className={`text-sm placeholder-gray ${
                                        distanceUnitErrors[element.id]
                                          ? 'border-red-500 text-red-500'
                                          : ''
                                      } ${
                                        selectedOption === 'mapArea' &&
                                        isPolygon
                                          ? 'border-0'
                                          : 'border-2 border-gray-500'
                                      }`}
                                      style={{ width: '100px' }}
                                      onChange={(e) =>
                                        handleDistanceUnitChange(
                                          element.id,
                                          e.target.value
                                        )
                                      }
                                      value={
                                        selectedValuesDistanceUnits[
                                          element.id
                                        ] || ''
                                      }
                                      disabled={
                                        !elementStates[`${element.id}`] ||
                                        (!isInputEnabled &&
                                          !elementStates[`${element.id}`]) ||
                                        (selectedOption === 'mapArea' &&
                                          isPolygon)
                                      }
                                    >
                                      <option value="" disabled hidden>
                                        Distance Units
                                      </option>
                                      {Object.entries(distanceUnitOptions).map(
                                        ([unit, label]) => (
                                          <option key={unit} value={unit}>
                                            {label}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <span style={{ color: 'red' }}>{eventNameError}</span>
                    <span style={{ color: 'red' }}>{startTimeError}</span>
                    <span style={{ color: 'red' }}>{endTimeError}</span>
                    <span style={{ color: 'red' }}>{checkboxError}</span>
                    {editable && activeStep === 1 && (
                      <div className="mt-4 ml-6">
                        <h2>{editLocationMessage}</h2>
                      </div>
                    )}
                    <div className="flex justify-between">
                      <div className="flex">
                        {activeStep !== 0 && (
                          <button
                            onClick={handleBack}
                            className="text-center px-5 py-2 text-white bg-green-500 w-30 h-10 rounded  uppercase mr-2"
                          >
                            Back
                          </button>
                        )}
                        <button
                          onClick={handleNext}
                          className={`text-center px-5 py-2 text-white ${
                            activeStep == 1 &&
                            selectedOption == 'mapArea' &&
                            outOfBound
                              ? 'bg-gray-500'
                              : 'bg-green-500'
                          } w-30 h-10 rounded uppercase`}
                          disabled={
                            activeStep == 1 &&
                            selectedOption == 'mapArea' &&
                            outOfBound
                          }
                        >
                          {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                        </button>
                      </div>
                      <button onClick={hancleCancel} className="text-center px-5 py-2 text-white bg-red-500 w-30 h-10 rounded  uppercase ml-96">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex relative justify-center">
                <button
                  className={`font-bold  rounded-full overflow-hidden mt-[-20px] mr-46  z-[900] focus:outline-none ${
                    dropDown ? 'ring ring-[#FF5733] ' : ''
                  }`}
                  onClick={handleButtonClick}
                >
                  <img
                    alt="chevron"
                    className={`rounded-full transform ${
                      showComponent ? 'rotate-0' : 'rotate-180'
                    }`}
                    src={chevronDown}
                    width="70"
                    height="70"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AlertSetting
