// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  MapContainer,
  Marker,
  TileLayer,
  WMSTileLayer,
  ZoomControl,
  useMap,
} from 'react-leaflet'
import { useEffect, useRef, useState } from 'react'
import NetworkData from '../interfaces/networkData'
import NetworkAdapter from '../services/NetworkAdapter'
import MainContainer from '../containers/MainContainer'
import MapChildComponent from './MapChildComponent'
import { useSelector } from 'react-redux'
import Spinner from '../Components/Spinner/Spinner'
import CustomMapMarker from '../Components/CustomMapMarker/CustomMapMarker'
import { RootState } from '../store'
import CurrentWeatherTab from '../Components/CurrentWeatherTab/CurrentWeatherTab'
import { useSlideOverDialogContext } from '../menus/SlideOverDialog/SlideOverDialogContext'
import { SurfaceObsAPIIconVisibility } from '../services/SurfaceObsAPI'
import TWSComponents from './TWSComponents'
import MarkerClusterGroup from '@changey/react-leaflet-markercluster'
import L from 'leaflet'
import './loader.css'
import drone from '../assets/drone2.gif'
require('./markerCluster.css')
;<link rel="stylesheet" href="./markerCluster.css" />
import WetDogWeatherLayers from '../Components/WetdogWeatherProducts/WetDogWeatherLayers'
import useErrorStatus from '../hooks/UseErrorStatus'
import { ContactUsButton } from '../Components/ContactUs/ContactUs'
import { SectionalChartComponent } from './BaseLayers'
import VerticalProfile from '../Components/VerticalProfile/VerticalProfile'
import Lightning from '../Components/Lightning/Lightning'
import { MapTypeButton } from './MapTypeComponent'

interface MapComponentProps {
  selectedSideNav: string
}

const SetTilePaneId = () => {
  const map = useMap()
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)

  useEffect(() => {
    const tilePane = map.getPane('tilePane')
    if (tilePane) {
      if (tilePane.children.length > 0)
        tilePane.children[0].id = 'basemap-tile-pane'
    }
  }, [darkMode])

  return null // This component does not render anything itself
}

const MapComponent = ({ selectedSideNav }: MapComponentProps) => {
  const [networkData, setnetworkData] = useState<NetworkData[] | null>(null)
  const mapState = useSelector((state: RootState) => state.mapState.darkMode)
  const selectedItemIds = useSelector(
    (state: RootState) => state.sideNav.selectedItemIds
  )
  const [darkMode, setDarkMode] = useState(mapState)
  const { setProductVisibility } = useSlideOverDialogContext()
  const [mapPosition, setMapPosition] = useState<any>({ lat: null, lng: null })
  const [isLoading, setIsLoading] = useState<boolean>(true)
  NetworkAdapter({ selectedSideNav, networkData, setnetworkData })
  const errorData = typeof networkData === 'string'
  const [showWMSLayer, setShowWMSLayer] = useState<boolean>(false)
  const markerRef = useRef<L.Marker | null>(null)
  let baseTileMapPlane: HTMLElement | null = null

  const mapRef = useRef(null)
  const errorStatus = useErrorStatus()

  let defaultLocation = useSelector((state: RootState) => {
    if (state.user.defaultLocation) {
      return state.user.defaultLocation
    }

    // if (sessionStorage.getItem('defaultLocation')) {
    if (localStorage.getItem('defaultLocation')) {
      return state.user.locations.filter(
        // (id: string) => id == sessionStorage.getItem('defaultLocation')
        (id: string) => id == localStorage.getItem('defaultLocation')
      )[0]
    }

    if (state.user.locations && state.user.locations.length > 0) {
      return state.user.locations[0] // Choose the first location as default
    }

    return null // Return null if there's no default location and the user has no locations
  })

  if (
    typeof defaultLocation === 'undefined' ||
    defaultLocation === null ||
    (typeof defaultLocation === 'object' &&
      Object.keys(defaultLocation).length === 0)
  ) {
    defaultLocation = {
      latitude: '39.8333333',
      longitude: '-98.585522',
    }
  }

  useEffect(() => {
    if (
      mapPosition.lat !== defaultLocation?.latitude ||
      mapPosition.lng !== defaultLocation?.longitude
    ) {
      setMapPosition({
        lat: defaultLocation?.latitude,
        lng: defaultLocation?.longitude,
      })
    }
    setIsLoading(false)
  }, [defaultLocation])

  useEffect(() => {
    if (selectedSideNav === 'Aeronautical') {
      setShowWMSLayer(!showWMSLayer)
    } else {
      setShowWMSLayer(false)
    }
  }, [selectedSideNav])

  const checkLoading = () => {
    return isLoading
  }

  useEffect(() => {
    setDarkMode(mapState)
    baseTileMapPlane = document.getElementById('basemap-tile-pane')
  }, [mapState])

  useEffect(() => {
    const selector = '.leaflet-pane.leaflet-tile-pane'

    const elements = Array.from(document.querySelectorAll(selector))
    const darkElements = Array.from(document.querySelectorAll('.dark-mode'))
    baseTileMapPlane = document.getElementById('basemap-tile-pane')
    if (mapState === true) {
      if (baseTileMapPlane) {
        baseTileMapPlane.style.filter = 'invert(1) hue-rotate(180deg)'
      }
    } else {
      elements.forEach((el) => {
        if (baseTileMapPlane) baseTileMapPlane.style.filter = ''
        // el.style.filter = ""
      })
    }
  }, [mapState])

  const eventHandlers = {
    add: (e: L.LeafletEvent) => {
      markerRef.current = e.target
    },
  }

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.openPopup()
    }
    baseTileMapPlane = document.getElementById('basemap-tile-pane')
  }, [])

  useEffect(() => {
    if (errorData) {
      errorStatus.addMessage(networkData, 400, 'error', true)
    }
  }, [errorData])

  const darkModeCSS =
    darkMode === true
      ? {
          filter:
            'brightness(90%) grayscale(100%) invert(1) contrast(120%) hue-rotate(166deg)',
        }
      : {}

  let tileSet, attribution
  const activeMapLayer = useSelector(
    (state: RootState) => state.mapState.activeBaseLayer
  )
  const lightningSelected = selectedItemIds.includes('observations-lightning')?true:false
  tileSet = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  attribution =
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'

  return (
    <MainContainer>
      {!isLoading && mapPosition.lat && mapPosition.lng ? (
        <MapContainer
          center={mapPosition}
          zoom={5}
          style={{ height: '100%', width: '100%' }}
          zoomControl={false}
          ref={mapRef}
        >
          <SetTilePaneId />
          <ZoomControl position="bottomright" />
          <div style={darkModeCSS}>
            <TileLayer attribution={attribution} url={tileSet} />
          </div>
          <div className="h-full w-auto flex flex-row justify-center items-center relative">
            <WetDogWeatherLayers />
          </div>
          <TWSComponents />
          {mapPosition.lat && mapPosition.lng && (
            <div style={{ filter: 'invert(100%) hue-rotate(180deg)' }}>
              {lightningSelected?<Lightning />:<></>}
              <Marker
                key="default-location-marker"
                position={mapPosition}
                eventHandlers={eventHandlers}
              />
              <MapChildComponent
                selectedSideNav={selectedSideNav}
              ></MapChildComponent>
              <MarkerClusterGroup maxClusterRadius={20}>
                {!errorData &&
                  networkData?.map((feature, index) =>
                    !feature.properties?.StationID &&
                    !feature.properties?.SensorType ? (
                      <CustomMapMarker
                        key={index}
                        index={index}
                        feature={feature}
                        tableView={true}
                        networkData={networkData}
                      />
                    ) : SurfaceObsAPIIconVisibility(
                        feature,
                        setProductVisibility
                      ) && setProductVisibility.length ? (
                      <CustomMapMarker
                        key={index}
                        index={index}
                        feature={feature}
                        tableView={true}
                        networkData={networkData}
                      />
                    ) : (
                      <div key={index}></div>
                    )
                  )}
                {selectedItemIds.includes('vertical-profile') && (
                  <VerticalProfile />
                )}
              </MarkerClusterGroup>
            </div>
          )}
          <div className="z-[9999998]">
            <ContactUsButton></ContactUsButton>
          </div>
        </MapContainer>
      ) : checkLoading() && !mapPosition.lat && !mapPosition.lng ? (
        <Spinner />
      ) : mapPosition.lat === undefined ||
        mapPosition.lng === undefined ||
        mapPosition.lat === null ||
        mapPosition.lng === null ? (
        <div
          style={{
            position: 'absolute',
            width: '250px',
            height: '250px',
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        >
          <div className="loader"></div>
          <img
            alt="loader"
            className="relative"
            src={drone}
            style={{
              left: 'calc(50% + 6px)',
              top: 'calc(50% + 6px)',
              transform: 'translateX(-50%) translateY(-50%)',
              filter: 'contrast(65%) brightness(203%)',
              width: '200px',
            }}
          />
        </div>
      ) : (
        <MapContainer
          center={[39.8283, -98.5795]}
          zoom={5}
          style={{ height: '100%', width: '100%' }}
          zoomControl={false}
        >
          <ZoomControl position="bottomright" />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <div className="absolute top-0 left-50 right-50">
            <MapChildComponent
              selectedSideNav={selectedSideNav}
            ></MapChildComponent>
          </div>
        </MapContainer>
      )}
      <div className="z-[9999998]">
        <CurrentWeatherTab />
        <MapTypeButton></MapTypeButton>
        <ContactUsButton></ContactUsButton>
      </div>
    </MainContainer>
  )
}

export default MapComponent
